//src/lib/Appwrite.js

import { Client, Account, ID } from 'appwrite';

const appwriteEndpoint = process.env.REACT_APP_APPWRITE_ENDPOINT;
const appwriteProjectId = process.env.REACT_APP_APPWRITE_PROJECT_ID;

const client = new Client()
    .setEndpoint(appwriteEndpoint)
    .setProject(appwriteProjectId);

// we handle all appwrite functions which use "account" in the front end here, and all other functions (debate/ users) in the backend
const account = new Account(client);

// get the current session
const getCurrentSession = async () => {
    try {
        return await account.getSession('current');
    } catch (error) {
        console.log('Failed to fetch current session');
        return null;
    }
};

// create a  session
const createSession = async (userId, token) => {
    console.log("createSession attempting to create a Session using: ", [userId, token])
    try {
        return await account.createSession(userId, token)
    } catch (error) {
        console.log('Failed to create session');
        return null;
    }
};

// get the account which is currently logged in
const getCurrentAccount = async () => {
    try {
        return await account.get();
    } catch (error) {
        console.error('Failed to fetch current user (Appwrite.js):', error);
        return null;
    }
};

// create a new account
const createAccount = async (email, password, name) => {
    console.log("Creating account for:", email);
    try {
        const newAccount = await account.create(ID.unique(), email, password, name);
        console.log('Account created:', newAccount);
        return newAccount;
    } catch (error) {
        console.error('Failed to create account:', error);
        throw error;
    }
};

// log in an account
const loginAccount = async (email, password) => {
    console.log("Logging in user:", email);
    try {
        const session = await account.createEmailPasswordSession(email, password);
        console.log('Logged in user:', email);
        return session;
    } catch (error) {
        console.error('Failed to log in user:', error);
        throw error;
    }
};

// log out an account
const logoutAccount = async () => {
    try {
        await account.deleteSession('current');
    } catch (error) {
        console.error('Failed to log out user:', error);
        throw error;
    }
};

export { client, account, createAccount, createSession, loginAccount, logoutAccount, getCurrentSession, getCurrentAccount };
