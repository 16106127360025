import React from 'react';
import '../styles/components/footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section contact-form">
        <h2>Contact Us</h2>
        <form>
          <input type="text" placeholder="Your Name" required />
          <input type="email" placeholder="Your Email" required />
          <textarea placeholder="Your Message" rows="5" required></textarea>
          <button type="submit">Send</button>
        </form>
      </div>

      <div className="footer-section social-links">
        <h2>Follow Us</h2>
        <ul>
          <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
          <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Newsletter</a></li>
          <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Blog</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
