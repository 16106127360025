// src/lib/ProtectedRoute.js

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

const PrivateRoute = () => {
  const { user, loading } = useAuthContext();

  if (loading) {
    // Show a loading spinner or null while the auth status is loading
    return <div>Loading...</div>;
  }

  if (!user) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, render the outlet (child routes)
  return <Outlet />;
};

export default PrivateRoute;
