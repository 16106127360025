export const concertsData = [
  
  {
    title: "Cellicious",
    date: "Winter 2024 (TBC)",
    description: "An exciting evening of beautiful cello music.",
    bio: "",
    location: "Hackney Empire",
    time: "upcoming",
    images: ["https://example.com/london-african-gospel-choir.jpg"],
    artist_page: "/london-african-gospel-choir",
    blog_pages: ["https://example.com/london-african-gospel-choir.jpg"],
    socials: {
      website: "",
      insta: "",
      facebook: "",
      tiktok: "",
      youtube: "",
      twitter: "",
      soundcloud: "",
      spotify: "",
    }
  },{
    title: "The London African Gospel Choir",
    date: "29 September 2024",
    description: "A special performance of uplifting gospel harmonies, infectious rhythms, and boundless energy.",
    bio: "",
    location: "Round Chapel, Hackney",
    time: "past",
    images: ["https://example.com/london-african-gospel-choir.jpg"],
    artist_page: "/london-african-gospel-choir",
    blog_pages: ["https://example.com/london-african-gospel-choir.jpg"],
    socials: {
      website: "",
      insta: "",
      facebook: "",
      tiktok: "",
      youtube: "",
      twitter: "",
      soundcloud: "",
      spotify: "",
    }

  },
{
    title: "Klezmer Band She’Koyokh",
    date: "12 May 2024",
    location: "Stoke Newington Town Hall",
    time: "past"
  },
  {
    title: "Sankofa by DJ NikNak and Visual Loëpa",
    date: "25 February 2024",
    location: "Hackney Round Chapel",
    time: "past"
  },
  {
    title: "Asian Music to Celebrate Diwali by Samii and Friends",
    date: "22 October 2023",
    location: "Hackney Empire",
    time: "past"
  },
  {
    title: "Vivaldi Four Seasons Recomposed by Max Richter",
    date: "20 August 2023",
    location: "Stoke Newington Town Hall",
    time: "past"
  },
  {
    title: "Choir & String Quartet by Victoria Park Singers and Kate Conway",
    date: "18 June 2023",
    location: "Stoke Newington Town Hall",
    time: "past"
  },
  {
    title: "International Women's Day Concert",
    date: "5 March 2023",
    location: "Hackney Round Chapel",
    time: "past"
  },
  {
    title: "Jally Kebba Susso Kora Player (West African Harp)",
    date: "22 December 2022",
    location: "Hackney Empire",
    time: "past"
  },
  {
    title: "London Afrobeat Collective",
    date: "20 May 2022",
    location: "Hackney Round Chapel",
    time: "past"
  },
  {
    title: "Cellofest",
    date: "3 November 2019",
    location: "Stoke Newington Town Hall",
    time: "past"
  },
  {
    title: "Kabantu World Music Ensemble",
    date: "31 March 2019",
    location: "Hackney Round Chapel",
    time: "past"
  },
  {
    title: "Live in Hackney by She'Koyokh",
    date: "17 October 2018",
    location: "Hackney Empire",
    time: "past"
  },
  {
    title: "Up Close and Personal by Three4",
    date: "24 June 2018",
    location: "Hackney Round Chapel",
    time: "past"
  },
  {
    title: "Palisander Recorder Quartet",
    date: "13 May 2018",
    location: "Hackney Empire",
    time: "past"
  },
  {
    title: "Jally Kebba Susso African Kora Player",
    date: "25 March 2018",
    location: "Hackney Round Chapel",
    time: "past"
  },

  // Concerts prior to 2018
  {
    title: "Battuta Baroque Orchestra - Vivaldi, Handel, Purcell, Couperin",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta - Zelenka, Farina, Rameau",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta - Purcell, Vivaldi, Pachelbel, Fasch, Biber, Bach",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta - Boccherini, Mozart, Haydn",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta - Handel Arias with Iestyn Davies, Counter-Tenor",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta - Bach, Handel, Corelli",
    date: "2005",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Battuta Pastorales & Noëls at Christmas",
    date: "2006",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "A Day in the Life of Baroque London",
    date: "2007",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "A Christmas Concert - Circle of Fifths Wind Ensemble",
    date: "2008",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "An Evening with the Mendelssohn Family - Battuta, David Owen Norris, Thomas Guthrie",
    date: "2009",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Mozart in the Round",
    date: "2010/11",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Bach’s Coffee Cantata",
    date: "2010/11",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Elements of Surprise - Vivaldi, Rebel, Telemann, Rameau",
    date: "2011/12",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "London Klezmer Quartet",
    date: "2011/12",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "A Brief History of Swingtime - The Cosmic Jazz Combo",
    date: "2011/12",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "18th Century Pearls - Emma Dogliani and Friends",
    date: "2012/13",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "With Strings Attached - Ken Aiso, Violin; Martin Fogel, Guitar",
    date: "2012/13",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Brazil in Song - Steve Lodder, Monica Vasconcelos and Friends",
    date: "2012/13",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Eastern European Klezmer Extravaganza - Merlin & Polina Shepherd",
    date: "2012/13",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Sing Joyfully: 500 Years of Hits from William Byrd to the Beatles - Voces8",
    date: "2013/14",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Bach Revealed - Matthew Barley, Cello",
    date: "2013/14",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Cosmic Jazz Combo - The Hackney Jazz Ensemble",
    date: "2013/14",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Peter and the Wolf - London Winds",
    date: "2014/15",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Handel in the Playhouse - L'Avventura",
    date: "2014/15",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Love, Grief, and Passion - Three4",
    date: "2014/15",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "London to Istanbul Express - She'Koyokh Gypsy Band",
    date: "2014/15",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Gulliver's Travels - Spiritato!",
    date: "2015/16",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Encore Battuta",
    date: "2015/16",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Decomposed Classical Mayhem",
    date: "2015/16",
    location: "Stoke Newington Assembly Room",
    time: "past"
  },
  {
    title: "Springtime - London Klezmer Quartet",
    date: "2015/16",
    location: "Stoke Newington Assembly Room",
    time: "past"
  }

];
