import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/pages/home.css';
// import cardFront from '../assets/images/hpHomeLogo3.png';
import mixpanel from 'mixpanel-browser';
import image from '../assets/images/hpHomeLogo2.png'
import background from '../assets/images/hpbackground.webp'
import battuta from '../assets/images/battuta.jpg'
import jally from '../assets/images/jally.jpg'

import HeroBanner from '../components/HeroBanner'; // Import the HeroBanner component
import cardFront from '../assets/images/hpHomeLogo3.png';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      let homeData = {};
      mixpanel.track('Home Page', homeData);
      console.log("tracked homeData: ", homeData);
    } catch (error) {
      console.error("Error tracking Home Page Mixpanel event:", error);
    }
  }, []);

  return (
    <div className="home-home-container">
      {/* Hero Banner Section */}
      <div className="home-hero-banner">
        <div className="home-hero-content">
          <img
            src={cardFront} // Use cardFront or cardBack as per your choice
            alt="Hackney Proms"
            className="home-logo-image"
          />
          <button className="home-learn-more-button" onClick={() => navigate('/about')}>
            Book Now
          </button>
        </div>
      </div>
      


      {/* Main About Section */}
      <div className="main-container">

      {/* Offer Section */}
      <section className="offer-section full-width-section">
        <div className="offer-text">
          <h2>Experience Music for Everyone</h2>
          <p>At Hackney Proms, we believe that everyone should have access to the joy of high-quality live music. Our pay-what-you-can ticket model ensures that everyone, regardless of income, can enjoy high-quality concerts right here in Hackney. Whether you’re a fan of world music, classical music, jazz, folk, or something else entirely, we’ve got something for everyone!</p>
          <button className="cta-button" onClick={() => navigate('/about')}>Learn More</button>
        </div>
        <img src={jally} alt="Live concert" className="section-image"/>
      </section>

{/* Join Community Section */}
<section className="join-community-section full-width-section parallax" style={{ backgroundImage: `url(${background})` }}>
  <div className="community-text">
    <h2>Join Our Community</h2>
    <p>Want to get involved? Whether you’re a musician, volunteer, or supporter, we’d love to have you as part of the Hackney Proms family. Sign up for updates and be the first to hear about new concerts and events.</p>
    <button className="cta-button" onClick={() => navigate('/contact')}>Sign Up Now</button>
  </div>
</section>

      {/* Features Section */}
      <section className="features-section full-width-section reverse ">
        <img src={battuta} alt="Concert crowd" className="section-image"/>
        <div className="features-text">
          <h2>What We Offer</h2>
          <ul>
            <li>Pay-What-You-Can pricing (tickets from £5-£15)</li>
            <li>Diverse musical genres: from classical to jazz to world music</li>
            <li>Family-friendly concerts that welcome audiences of all ages</li>
            <li>Local venues across Hackney for a truly community-based experience</li>
          </ul>
          <button className="cta-button" onClick={() => navigate('/concerts')}>Check Out Concerts</button>
        </div>
      </section>

{/* Support Section */}
<section className="support-section full-width-section" style={{ backgroundImage: `url(${background})` }}>
  <div className="support-text">
    <h2>Support the Proms</h2>
    <p>Your contributions help us keep the music alive. Consider donating, sponsoring, or simply paying what you can to attend our concerts. Your support ensures that Hackney Proms continues to offer affordable, accessible music for all.</p>
    <button className="cta-button" onClick={() => navigate('/support')}>Support Hackney Proms</button>
  </div>
</section>


      {/* Upcoming Concerts Section */}
      <section className="upcoming-concerts-section full-width-section">
        <h2>Upcoming Concerts</h2>
        <p>Don’t miss out on our upcoming performances! Check our Upcoming Concerts page to see what’s next in the Hackney Proms lineup.</p>
        <button className="cta-button" onClick={() => navigate('/concerts')}>View All Concerts</button>
      </section>
      
      </div>
    </div>
  );
};

export default Home;
