// src/context/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { getCurrentSession, getCurrentAccount, loginAccount, logoutAccount } from '../lib/Appwrite';

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            console.log('Fetching current user... (from AuthContext)');
            try {
                const currentSession = await getCurrentSession();
                if (currentSession) {
                    try {
                        const currentUser = await getCurrentAccount();
                        console.log('User fetched successfully:', currentUser);
                        setUser(currentUser);

                        // Store user.$id
                        localStorage.setItem('userId', currentUser.$id);
                        mixpanel.identify(currentUser.$id);

                    } catch (error) {
                        console.error('Failed to fetch user data:', error);
                        setUser(null);
                    }
                } else {
                    throw new Error('No active session found');
                }
            } catch (error) {
                console.error('No active session');
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);
    

    const login = async (email, password) => {
        try {
            await loginAccount(email, password);
        } catch (error) {
            console.error('Failed to create email session:', error);
            throw error;
        }

        let currentUser;
        try {
            currentUser = await getCurrentAccount();
            setUser(currentUser);

            // Store user.$id in local storage
            localStorage.setItem('userId', currentUser.$id);
        } catch (error) {
            console.error('Failed to fetch user after login (AuthContext.js):', error);
            throw error;
        }

        
    };

    const logout = async () => {
        try {
            await logoutAccount();
            setUser(null);
            localStorage.removeItem('userId');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
