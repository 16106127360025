import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/pages/support.css';
import mixpanel from 'mixpanel-browser';
import Hero from '../components/Hero';
import heroImage from '../assets/images/hpbackground.svg';
import hackney from '../assets/images/hackney.jpg';
import volunteerImage from '../assets/images/hackney.jpg';
import donateImage from '../assets/images/hackney.jpg';
import signupImage from '../assets/images/hackney.jpg';

const Support = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      mixpanel.track('Support Page');
      console.log("tracked Support Page");
    } catch (error) {
      console.error("Error tracking Support Page Mixpanel event:", error);
    }
  }, []);

  return (
    <div className="support-page-container">
      {/* Hero Banner Section */}
      <Hero image={heroImage} title="Support Us" subtitle="Join us in making live music accessible to everyone." />

      {/* Main Support Section */}
      <div className="main-container">

        {/* Become a Friend Section */}
        <section className="support-page-section full-width-section reverse">
          <div className="offer-text">
            <h2>Become a Friend of Hackney Proms</h2>
            <p>Support our mission by becoming a Friend of Hackney Proms. For just £5 per month, you’ll enjoy exclusive benefits like free tickets, a Hackney Proms t-shirt & tote bag, free snacks at concerts, and more!</p>
            <button className="cta-button" onClick={() => navigate('/become-a-friend')}>Join as a Friend</button>
          </div>
          <img src={hackney} alt="Become a Friend" className="section-image"/>
        </section>

        {/* Volunteer Section with Parallax Background */}
        <section className="support-page-section full-width-section parallax has-background" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="community-text">
            <h2>Volunteer with Us</h2>
            <p>Lend your time and skills to help us create unforgettable events. From event setup to community engagement, your support ensures the success of Hackney Proms.</p>
            <button className="cta-button" onClick={() => navigate('/volunteer')}>Sign Up to Volunteer</button>
          </div>
          <img src={volunteerImage} alt="Volunteer" className="section-image"/>
        </section>

        {/* Donate Section */}
        <section className="support-page-section full-width-section reverse">
          <div className="features-text">
            <h2>Donate to Support Our Mission</h2>
            <p>Your generous donation helps us keep ticket prices low, making live music accessible to everyone in Hackney. We partner with the <a href='https://www.cafonline.org/' target="_blank" rel="noopener noreferrer">Charities Aid Foundation (CAF)</a> to securely process your donations, which makes it easier for you to support us while also ensuring your contribution goes further through <a href='https://www.cafonline.org/personal-giving/effective-ways-to-give/giving-account/how-does-gift-aid-work' target="_blank" rel="noopener noreferrer">Gift Aid</a>. Gift Aid allows UK taxpayers to increase their donation by 25% at no extra cost to them, giving us more funds to continue our work.</p>
            <p>At Hackney Proms, we operate at a loss to subsidise the incredible music we make accessible to our community. Your donations are essential in helping us bridge the gap and ensure that world-class performances remain affordable for all. Every contribution, big or small, makes a lasting impact.</p>
            <button className="cta-button" onClick={() => window.open('https://cafdonate.cafonline.org/12066', '_blank', 'noopener noreferrer')}>Donate Now</button>
          </div>
          <img src={donateImage} alt="Donate" className="section-image"/>
        </section>

        {/* Sign Up for Updates Section with Parallax Background */}
        <section className="support-page-section full-width-section parallax has-background" style={{ backgroundImage: `url(${heroImage})` }}>
          <div className="support-text">
            <h2>Stay Connected</h2>
            <p>Sign up for our newsletter to get the latest news on upcoming concerts, events, and ways you can support Hackney Proms.</p>
            <button className="cta-button" onClick={() => navigate('/signup')}>Sign Up for Updates</button>
          </div>
          <img src={signupImage} alt="Sign Up" className="section-image"/>
        </section>

      </div>
    </div>
  );
};

export default Support;
