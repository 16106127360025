import React from 'react';
import '../styles/components/hero.css';

const Hero = ({ image, title }) => {
  return (
    <div className="hero-container" style={{ backgroundImage: `url(${image})` }}>
      <div className="hero-overlay">
        <h1 className="hero-title">{title}</h1>
      </div>
    </div>
  );
};

export default Hero;
