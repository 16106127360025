// src/pages/Login.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import '../styles/pages/auth.css';
import mixpanel from 'mixpanel-browser';

const Login = () => {
  const { login } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/');
    } catch (error) {
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };

  useEffect(() => {
    try {
      let loginData = {
      }
      mixpanel.track('Login Page', loginData);
      console.log("tracked loginData: ",loginData)
    } catch  (error) {
      console.error("Error tracking Login Page Mixpanel event:", error);
    }
  }, []);

  return (
    <div className="auth-container">
      <div className="auth-form">
        {/* <h1>Login</h1> */}
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
        {/* <p>
          Don't have an account? <a href="/signup">Sign Up</a>
        </p>
        <p>
          <a href="/password-reset-request">Forgot Password?</a>
        </p> */}
      </div>
    </div>
  );
};

export default Login;
