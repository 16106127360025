import React, { useState, useEffect, useRef } from 'react';
import '../styles/components/peopleSlider.css';
import testImage1 from '../assets/images/1.png';
import testImage2 from '../assets/images/2.png';
import testImage3 from '../assets/images/3.png';
import testImage4 from '../assets/images/4.png';
import testImage5 from '../assets/images/5.png';
import testImage6 from '../assets/images/6.png';
import testImage7 from '../assets/images/7.png';
import testImage8 from '../assets/images/8.png';

const teamMembers = [
    {
      name: "Kate Conway",
      role: "Artistic Director",
      description: "Kate is a violinist, music educator and founder-director of Suzuki Hub, based in East London, where she teaches Suzuki violin. A founder member of the classical band three4, she also performs regularly as a soloist and in various chamber groups and orchestras in and around London. She cares passionately about diversity and inclusion and is working hard to diversify music and music education.",
      image: testImage8, 
    },
    {
      name: "Claire Jervis",
      role: "Treasurer",
      description: "Claire is a Chartered Financial Analyst working in sustainable finance, with over 7 years of experience. She invests in companies making a positive impact on society and the environment. Having been fortunate enough to have attended a council-run music school as a child, Claire is passionate about ensuring access to music for all. She loves Hackney for its diversity and enjoys seeing all types of artists and musicians perform live!",
      image: testImage1, 
    },
    {
      name: "Julie Scott",
      role: "Secretary",
      description: "Julie has extensive experience of working with Trustee Boards and Committees following a 30 year career in both the public and private sectors. Julie moved to Hackney in 1994 and following her retirement in 2019 has been involved with a number of local charities including Hackney Growing Communities. She is delighted to be working with Hackney Proms.",
      image: testImage2, 
    },
    {
      name: "Barry Hindmarsh",
      role: "Trustee",
      description: "Barry Hindmarch is a Fellow of the Chartered Institute of Management Accountants and has worked in the City of London for over 25 years in various senior financial roles. When his two young children started studying violin at Suzuki Hub he realized what an amazing gift it is to play and enjoy live music, and he is very proud to be spreading this message through his role as Trustee for Hackney Proms.",
      image: testImage3, 
    },
    {
      name: "Dipti Morjaria",
      role: "Trustee",
      description: "Dipti is a safeguarding facilitator and consultant, providing front-line health and social care to children and young people. Dipti enjoys working with local community groups, like the Hackney Proms, helping children and young people engage in positive activities and experiences in places that provide safe spaces where they can express their feelings, explore their identity, leaving them feeling good about themselves.",
      image: testImage4, 
    },
    {
      name: "David Green",
      role: "Schools Liaison",
      description: "David is a chartered accountant and retired businessman with 30 years experience running SMEs in the fashion industry. With experience in mentoring and as a school governor, he is ideal to be the Hackney Proms contact with schools. Retirement has meant more time to indulge in his love of music by doing some DJ-ing under the name of Deckless! He is excited to be part of an organisation that promotes live music and musicians across all genres in Hackney.",
      image: testImage5, 
    },
    {
      name: "Harshini Jayakumar",
      role: "Social Media Coordinator - Volunteer",
      description: "Harshini graduated in July 2023 with a Masters in Digital Marketing, from Loughborough University. She co-manages all the social media platforms of Hackney proms by posting announcements about the upcoming events and photos/videos of the events. She is very excited to be a part of Hackney Proms to promote exceptionally talented musicians across all genres in Hackney.",
      image: testImage6, 
    },
    {
      name: "Sangeetha Singh",
      role: "Social Media Coordinator - Volunteer",
      description: "Sangeetha graduated from Loughborough University with a Masters in Digital Marketing. She co-manages all the social media aspects of Hackney Proms. She is very passionate about promoting exceptionally talented musicians of all genres and being a part of Hackney Proms.",
      image: testImage7, 
    },
  ];


  const PeopleSlider = () => {
    const sliderRef = useRef(null);
  
    return (
      <div className="slider">
        <div className="slider-container" ref={sliderRef}>
          {teamMembers.map((member, index) => (
            <div className="slider-content" key={index}>
              <img src={member.image} alt={member.name} className="slider-image" />
              <div className="slider-text">
                <h3>{member.name}, {member.role}</h3>
                <p>{member.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default PeopleSlider;