// src/components/HeroBanner.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/components/heroBanner.css';

// HeroBanner component definition
const HeroBanner = ({ isGreyedOut, image }) => {
    const navigate = useNavigate();
  return (
    <div className="hero-banner">
        {isGreyedOut && <div className="grey-overlay"></div>}
    <div className="hero-content">
      <img
        src={image} // Use cardFront or cardBack as per your choice
        alt="Hackney Proms"
        className="logo-image"
      />
      <button className="learn-more-button" onClick={() => navigate('/about')}>
        Book Now
      </button>
    </div>
  </div>
  );
};

export default HeroBanner;
