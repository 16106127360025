//src/index.js


import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import mixpanel from 'mixpanel-browser';


mixpanel.init('bb9d33a5a1ac8ae0881b59450b21e038', {
  debug: true,
  track_pageview: false,
  persistence: 'localStorage',
  ignore_dnt: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
