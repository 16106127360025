import React, { useEffect } from 'react';
import '../styles/pages/about.css'; // Updated About page styling
import Hero from '../components/Hero';
import mixpanel from 'mixpanel-browser';
import PeopleSlider from '../components/PeopleSlider'; // Import the custom PeopleSlider component

import council from '../assets/images/hpHomeLogo.png';
import battuta from '../assets/images/battuta.jpg';
import jally from '../assets/images/jally.jpg';
import hackney from '../assets/images/hackney.jpg';
import heroImage from '../assets/images/hpbackground.svg';

const About = () => {
  useEffect(() => {
    try {
      mixpanel.track('About Page');
      console.log('tracked About Page');
    } catch (error) {
      console.error('Error tracking About Page Mixpanel event:', error);
    }
  }, []);

  return (
    <div className="about-container">
      {/* Hero Section for About */}
      <Hero image={heroImage} title="Our Story" />

      {/* Main Content Section aligned with the Home page */}
      <div className="main-container">

        {/* Section 1: Our Beginnings */}
        <section className="standard-section">
          <div className="text-image-group reverse">
            <img src={battuta} alt="Hackney Proms Logo" className="section-image" />
            <div className="text-content">
              <h2>Early Days</h2>
              <p>
                The Hackney Proms is a registered charity founded in 2004 by a group of
                music-loving Hackney residents with a passion for enriching the cultural life
                of the borough. Their mission was simple: to make high-quality orchestral
                music accessible to the entire community.
              </p>
            </div>
          </div>
        </section>

        {/* Section 2: The Hackney Proms Today */}
        <section className="standard-section">
          <div className="text-image-group">
            <img src={jally} alt="Hackney Proms Logo" className="section-image" />
            <div className="text-content">
              <h2>The Hackney Proms Today</h2>
              <p>
                While we continue to bring world-class performances to our community, Hackney
                Proms has flourished, both in reach and impact. What began as a celebration
                of orchestral music has grown into a diverse program featuring world, folk,
                jazz, and more. As we've expanded into larger venues, we've remained
                committed to making music accessible to all - both through inclusive
                programming and by ensuring our venues are welcoming and accessible for
                everyone, including those with mobility needs.
              </p>
            </div>
          </div>
        </section>

        {/* Section 3: Mission */}
        <section className="standard-section">
          <div className="text-image-group reverse">
            <img src={hackney} alt="Hackney Proms Logo" className="section-image" />
            <div className="text-content">
              <h2>Mission</h2>
              <p>
                Our mission is to make sure that everyone in Hackney, regardless of age,
                background, or income, has equal access to live music of the highest quality.
              </p>
            </div>
          </div>
        </section>

        {/* Section 4: Our Values */}
        <section className="standard-section">
          <div className="text-image-group">
            <img src={council} alt="Hackney Proms Logo" className="section-image" />
            <div className="text-content">
              <h2>Our Values</h2>
              <ul className="steps-list">
                <li>
                  <strong>Diversity:</strong> We believe in the power of diversity - both in
                  music and in our community.
                </li>
                <li>
                  <strong>Inclusivity:</strong> We strive to create a welcoming and inclusive
                  environment at every event.
                </li>
                <li>
                  <strong>Accessibility:</strong> We prioritize accessibility, from our venue
                  choices to our family-friendly nature.
                </li>
                <li>
                  <strong>Creativity:</strong> We inspire creativity and curiosity through
                  engaging, diverse musical experiences.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Section 5: Meet Our Team - Custom Slider */}
        <section className="standard-section">
          <h2>Meet Our Team</h2>
          <PeopleSlider />
        </section>
      </div>
    </div>
  );
};

export default About;
