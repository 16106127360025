import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { concertsData } from '../data/concertsData';
import '../styles/pages/concerts.css';
import cardFront from '../assets/images/cellicious_annoucement.png';

import HeroBanner from '../components/HeroBanner'; // Import the HeroBanner component

const Concerts = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('upcoming');

  // Filter concerts based on the selected category
  const filteredConcerts = concertsData.filter(concert => concert.time === selectedCategory);

  return (
    <div className="concerts-container">
      {/* Hero Banner with grey overlay */}
      <HeroBanner isGreyedOut={false} image={cardFront} />

      <div className="chip-container">
        <button
          className={`chip ${selectedCategory === 'upcoming' ? 'active' : ''}`}
          onClick={() => setSelectedCategory('upcoming')}
        >
          Coming Soon
        </button>
        <button
          className={`chip ${selectedCategory === 'past' ? 'active' : ''}`}
          onClick={() => setSelectedCategory('past')}
        >
          Past
        </button>
      </div>

      <div className="concerts-list">
        {filteredConcerts.map((concert, index) => (
          <div key={index} className="concert-card">
            {/* Conditional Rendering: Render only if the data exists */}
            {concert.images && concert.images[0] && (
              <img src={concert.images[0]} alt={concert.title} className="concert-image" />
            )}
            {concert.title && <h3>{concert.title}</h3>}
            {concert.date && <p>{concert.date}</p>}
            {concert.location && <p>{concert.location}</p>}
            {concert.description && <p>{concert.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Concerts;
