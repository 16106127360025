import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Concerts from './pages/Concerts';
import Support from './pages/Support';

import Login from './pages/Login';

import AuthProvider from './context/AuthContext';
import PrivateRoute from './lib/ProtectedRoute';
import ScrollToTop from './lib/ScrollToTop';

const Layout = ({ children }) => {
  const location = useLocation();
  
  // Paths where you don't want the footer to appear
  const hideFooterPaths = ['/login', '/signup', '/password-reset'];

  return (
    <>
      <Header />
      {children}
      {/* Conditionally render the Footer */}
      {!hideFooterPaths.includes(location.pathname) && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop />

      <AuthProvider>
            <div className="App">
              <Routes>
                
                {/* Public Routes */}
                <Route path="/login" element={<Login />} />

                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Layout><Home /></Layout>} />
                  <Route path="/about" element={<Layout><About /></Layout>} />
                  <Route path="/support" element={<Layout><Support /></Layout>} />
                  <Route path="/concerts" element={<Layout><Concerts /></Layout>} />
                </Route>
                
              </Routes>
            </div>
      </AuthProvider>
    </Router>
  );
}

export default App;