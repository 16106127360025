import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { useAuthContext } from '../context/AuthContext';

import headerLogo from '../assets/images/hpLogo.svg';
import loggedInAvatar from '../assets/images/loggedIn.svg';
import loggedOutAvatar from '../assets/images/loggedOut.svg';

import '../styles/components/header.css';

function Header() {
    const { user, logout } = useAuthContext();
    const location = useLocation();
    const [isAvatarDropdownOpen, setIsAvatarDropdownOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);  // State for controlling the burger menu

    // Determine if the current route is the home page
    const isHomePage = location.pathname === '/' || location.pathname === '/login';

    useEffect(() => {
        // Close the avatar dropdown and burger menu when the route changes
        setIsAvatarDropdownOpen(false);
        setIsMenuOpen(false);  // Close burger menu on route change
    }, [location.pathname]);

    const toggleAvatarDropdown = () => {
        setIsAvatarDropdownOpen(!isAvatarDropdownOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);  // Toggle burger menu
    };

    return (
        <header>
            <nav
                className={`navbar navbar-expand-lg fixed-top ${isHomePage ? '' : 'navbar-colored'}`}
                role="navigation"
            >
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={headerLogo} alt="Logo" className="navbar-logo" />
                    </Link>

                    {/* Hamburger menu for mobile */}
                    <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                        <FaBars size={25} />
                    </button>

                    {/* Middle section for "About, Concerts, Support" */}
                    <div className={`navbar-center ${isMenuOpen ? 'open' : ''}`}>
                        <Link className="nav-item nav-link" to="/about">About</Link>
                        <Link className="nav-item nav-link" to="/concerts">Concerts</Link>
                        <Link className="nav-item nav-link" to="/support">Support</Link>
                    </div>

                    <div className="navbar-icons ms-auto">
                        <div className="nav-item dropdown">
                            <img
                                src={user ? loggedInAvatar : loggedOutAvatar}
                                alt="Avatar"
                                className="user-avatar nav-link dropdown-toggle"
                                id="avatarDropdown"
                                role="button"
                                onClick={toggleAvatarDropdown}
                                aria-expanded={isAvatarDropdownOpen}
                            />
                            <ul
                                className={`dropdown-menu ${isAvatarDropdownOpen ? 'show' : ''}`}
                                aria-labelledby="avatarDropdown"
                            >
                                {user ? (
                                    <>
                                        <li><button className="dropdown-item" onClick={logout}>Logout</button></li>
                                    </>
                                ) : (
                                    <>
                                        <li><Link className="dropdown-item" to="/login">Login/ Register</Link></li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
